import { gql } from '@urql/core';

export const LoginMutation = gql`
  mutation ($email: String!, $password: String!) {
    companyManagerLogin(email: $email, password: $password) {
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

export const LogoutMutation = gql`
  mutation {
    companyManagerLogout {
      authenticatable {
        email
      }
    }
  }
`;

export const RegisterMutation = gql`
  mutation (
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    companyManagerRegister(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      credentials {
        accessToken
        client
        expiry
        uid
      }
    }
  }
`;

export const RequestPasswordResetMutation = gql`
  mutation ($email: String!, $redirectUrl: String!) {
    companyManagerSendPasswordResetWithToken(
      email: $email
      redirectUrl: $redirectUrl
    ) {
      message
    }
  }
`;

export const ResetPasswordMutation = gql`
  mutation (
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    companyManagerUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        email
      }
    }
  }
`;

import { gql } from '@urql/core';

export const DatePresetQuery = gql`
  query {
    datePresets: __type(name: "DatePreset") {
      values: enumValues {
        name
      }
    }
  }
`;

import { gql } from '@urql/core';

export const CompanyQuery = gql`
  query ($datePreset: DatePreset!) {
    company {
      averageBookingLength(datePreset: $datePreset)
      workingDays(datePreset: $datePreset)
      topHosts(datePreset: $datePreset) {
        bookingsCount
        name
        city
      }
      topUsers(datePreset: $datePreset) {
        bookingsCount
        firstName
        lastName
      }
      bookingActivity(datePreset: $datePreset) {
        date
        hours
        employeeId
      }
    }
  }
`;

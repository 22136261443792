import React from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Provider, client } from 'lib/graphql';

import Notifications from 'components/Notifications';
import Drift from 'components/Script/Drift';
import GTM from 'components/Script/GTM';
import Heap from 'components/Script/Heap';

import '../styles/globals.css';

dayjs.extend(duration);

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);

  React.useEffect(() => {
    defineCoefficientCssVariableForInnerHeightOnMobileDevices();
    defineCoefficientCssVariableForVhOnMobileDevices();
  }, []);

  return (
    <>
      <GTM />
      <Heap />
      <Drift />

      <Provider value={client}>
        {getLayout(<Component {...pageProps} />)}
      </Provider>
      <Notifications />
    </>
  );
}

function defineCoefficientCssVariableForInnerHeightOnMobileDevices() {
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty(
      '--window-inner-height',
      `${window.innerHeight}px`
    );
  });
}

function defineCoefficientCssVariableForVhOnMobileDevices() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export default MyApp;

import { gql } from '@urql/core';

export const EmployeesExportUrlQuery = gql`
  query {
    path: employeesCsvExport {
      path
    }
  }
`;

export const EmployeesQuery = gql`
  query {
    employees {
      id
      email
      firstName
      lastName
      monthlyAllowance
      credits {
        balance
        usedThisMonth
      }
      bookingActivity(datePreset: LIFETIME) {
        hours
      }
    }
  }
`;

export const EmployeeQuery = gql`
  query ($id: ID!, $datePreset: DatePreset!) {
    employee: node(id: $id) {
      id
      ... on Employee {
        id
        email
        firstName
        lastName
        monthlyAllowance
        averageBookingLength(datePreset: $datePreset)
        credits {
          balance
          usedThisMonth
        }
        bookingActivity(datePreset: $datePreset) {
          hostName
          date
          hours
          employeeId
          startAt
          endAt
          credits
          id
        }
        topHosts(datePreset: $datePreset) {
          bookingsCount
          name
          city
        }
      }
    }
  }
`;

export const InvitesQuery = gql`
  query {
    invites {
      email
      firstName
      lastName
      monthlyAllowance
    }
  }
`;
